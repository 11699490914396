import React from 'react'

function education() {
    return (
        <div>
            <div className="vcamp-feature-section-seven mt-70">
				<div className="container">
					<div className="row">
						<div className="col-xxl-7 col-xl-8 col-lg-6 col-md-8 m-auto" data-aos="fade-up">
							<div className="title-style-one text-center">
								<h2 className="title">Education</h2>
							</div>
						</div>
					</div>
				</div>
				<div className="box-layout mt-40 lg-mt-10">
					<div className="row">
						<div className="col-xxl-11 m-auto">
							<div className="row"  style={{justifyContent:'center'}}>
								<div className="col-md-4 col-sm-6 d-flex mt-45" data-aos="fade-up">
									<div className="card-style-seven">
										<h4>Middlesex University</h4>
										<p>Bachelor of Science Information Technology & Business information Systems</p>
									</div> 
								</div>
								<div className="col-md-4 col-sm-6 d-flex mt-45" data-aos="fade-up" data-aos-delay="100">
									<div className="card-style-seven">
										<h4>National Institute of Information Technology</h4>
										<p>Higher National Diploma Software Engineering</p>
									</div> 
								</div>
								{/* <div className="col-md-4 col-sm-6 d-flex mt-45" data-aos="fade-up" data-aos-delay="150">
									<div className="card-style-seven">
										<h4>Enterprise Service</h4>
										<p>Take a deep dive and try our <span>listof over 40</span> unique <span>generators</span>, find placeholder images for your next</p>
									</div> 
								</div> */}
							</div>
						</div>
					</div>
				</div> 
				<img src="images/shape/shape_36.svg" alt="" className="shapes shape-one"/>
				<img src="images/shape/shape_37.svg" alt="" className="shapes shape-two"/>
			</div> 

        </div>
    )
}

export default education
