import React from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import "./insight.css";

function Insights({ data }) {
  return (
    <div>
      <Header />
      <div className="blog-section-two mt-120 pt-100 border-top mb-120">
        <div className="container">
          <div className="row">
            <div className="row align-items-center mb-50 lg-mb-20">
              <div className="col-sm-6">
                <div className="title-style-two">
                  <h3 className="title">
                    <span className="fonty" style={{ fontFamily: "Recoleta" }}>
                      Insights
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            {data.map((item, index) => (
              //   <div className="col-xxl-11 m-auto">

              // <div className="row gx-xl-5">
              <div className="col-md-4" key={index}>
                <article className="blog-meta-two mt-40">
                  <div className="img-meta div-img">
                    <div
                      className="blog-img"
                      style={{
                        backgroundImage: `url(${item.thumbnail})`,
                        height: "300px",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "12px",
                        backgroundPosition: "center",
                      }}
                    ></div>
                  </div>
                  <div className="post-meta">
                    <div
                      className="date"
                      style={{ fontFamily: "gorditamedium" }}
                    >
                      {item.pubDate}
                    </div>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      className="title"
                      style={{ textDecoration: "none" }}
                    >
                      {item.title}
                    </a>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      className="theme-btn-three"
                      style={{ textDecoration: "none" }}
                    >
                      Continue reading <i className="fas fa-angle-right"></i>
                    </a>
                  </div>
                </article>
              </div>

              // </div>
              //   </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Insights;
