import React from "react";

function miniAbout() {
  return (
    <div>
      <div className="vcamp-text-block-one mt-150 md-mt-80">
        <div className="container">
          <div className="row">
            <div
              className="col-xxl-5 col-lg-6 ms-auto order-lg-last"
              data-aos="fade-left"
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h3 className="title" style={{ fontSize: "37px" }}>
                    I help companies adopt technology <br /> and drive their
                    digital <br /> adoption journey
                  </h3>
                </div>
                <p
                  className="meta-info-text text-lg"
                  style={{ fontSize: "18px" }}
                >
                  I Innovate and build technology products and solutions for
                  different industries, help companies with their adoption of
                  technology and provide high quality scalable software that
                  help organisations to deliver efficient services to their own
                  clients.
                </p>
                <a href="/about" className="theme-btn-two">
                  About Me
                </a>
              </div>
            </div>
            <div className="col-xxl-6 col-lg-6" data-aos="fade-up">
              <div className="img-container position-relative">
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}images/boss-career-10.jpg`}
                  alt=""
                  className="ceo-avatar"
                  style={{ borderRadius: "15px", width: "75%" }}
                />
                <div className="quote-wrapper">
                  <img src="images/icon/icon_10.svg" alt="" className="icon" />
                  <blockquote>
                    Growing up isn't finite! At some point, you become something
                    and it just won't end there, you can become a lot, we all
                    have the energy to do anything! No need killing to become
                    one thing when you can just become you.
                  </blockquote>
                  <h6 className="name">Ugi Augustine Ugi JNR</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="images/shape/shape_05.svg"
          alt=""
          className="shapes shape-two"
        />
      </div>
    </div>
  );
}

export default miniAbout;
