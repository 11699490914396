import React from "react";
const imageUrl = process.env.REACT_APP_IMAGE_URL;
const charityImages = [
  `${imageUrl}images/boss-career-07.jpg`,
  `${imageUrl}images/boss-career-13.jpg`,
  `${imageUrl}images/boss-career-15.jpg`,
  `${process.env.REACT_APP_IMAGE_URL}images/boss-career-18.jpg`,
  `${process.env.REACT_APP_IMAGE_URL}images/boss-career-12.jpg`,
  `${process.env.REACT_APP_IMAGE_URL}images/boss-career-04.jpg`,
  `${process.env.REACT_APP_IMAGE_URL}images/boss-career-06.jpg`,
  `${process.env.REACT_APP_IMAGE_URL}images/boss-career-18.jpg`,
  `${process.env.REACT_APP_IMAGE_URL}images/boss-career-06.jpg`,
  `${process.env.REACT_APP_IMAGE_URL}images/Career/20210909_172442.jpg`,
  `${process.env.REACT_APP_IMAGE_URL}images/Career/20210909_173315.jpg`,
  `${process.env.REACT_APP_IMAGE_URL}images/Career/boss.jpg`,
];

function CareerTab() {
  return (
    <div>
      {charityImages.map((images, id) => (
        <div className="isotop-item custom" key={id}>
          <div className="case-block-six">
            <div className="img-box">
              <img src={images} alt="" />
            </div>
            <div className="hover-valina">
              <div>
                <a
                  className="fancybox position-relative zoom"
                  data-fancybox=""
                  href={images}
                  tabindex="0"
                >
                  <i className="bi bi-arrows-angle-expand"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
      {/* 2 */}
    </div>
  );
}

export default CareerTab;
