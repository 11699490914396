import Education from "../education/education";
import Footer from "../footer/footer";
// import Loader from '../loader'
import Header from "../header/header";
import { Link } from "react-router-dom";
import React from "react";
import Timeline from "../timeline/timeline";
import WorkHistory from "../workHistory/workHistory";

function career() {
  return (
    <div>
      {/* <Loader/> */}
      <Header />
      <div className="hero-banner-eight">
        <div className="banner-content h-100 d-md-flex align-items-center justify-content-between">
          <div className="text-wrapper">
            <h1 className=" font-recoleta">Career.</h1>
            <p className="hero-sub-heading text-lg">
              Tech and business executive with a mission to empower people and
              organisations to achieve more
            </p>
            <a href="#work" className="theme-btn-eleven ripple-btn">
              Read More
            </a>
          </div>

          <div className="img-wrapper position-relative">
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}images/boss-career-06.jpg`}
              style={{ borderRadius: "12px" }}
              alt=""
            />
          </div>
        </div>
      </div>
      <WorkHistory />
      <Education />

      <div
        className="vcamp-feature-section-seven mt-70"
        style={{ background: "none" }}
      >
        <Timeline />
        <img
          src="images/shape/shape_36.svg"
          alt=""
          className="shapes shape-one"
        />
        <img
          src="images/shape/shape_37.svg"
          alt=""
          className="shapes shape-two"
        />
      </div>
      <Footer />
    </div>
  );
}

export default career;
