import { Link } from "react-router-dom";
import React from "react";

function footer() {
  return (
    <div>
      <div className="vcamp-footer-one box-layout">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xxl-11 m-auto">
                <div className="top-footer mt-80 md-mt-70">
                  <div className="row">
                    <div className="col-lg-4 col-md-2">
                      <div className="logo">
                        <Link to="/">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}images/UAU-blue.png`}
                            alt=""
                            width="127"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-4">
                      <h6 className="footer-title">Charity Organisations</h6>
                      <ul className="footer-nav-link style-none">
                        <li>
                          <a href="#">
                            {" "}
                            <span>Artty Care Foundation</span>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            {" "}
                            <span>Happy feet for kidz Foundation</span>{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-4">
                      <h6 className="footer-title">Links</h6>
                      <ul className="footer-nav-link style-none">
                        <li>
                          <Link to="/about">
                            {" "}
                            <span>About</span>{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/career">
                            {" "}
                            <span>Career</span>{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/insights">
                            {" "}
                            <span>Insights</span>{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4">
                      <h6 className="footer-title">Get In Touch</h6>
                      <ul className="footer-nav-link style-none">
                        <li>
                          <a href="mailto:docaustyn@gmail.com">
                            {" "}
                            <span>docaustyn@gmail.com</span>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="mailto:ceo@nugitech.com">
                            {" "}
                            <span>ceo@nugitech.com</span>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="tel:+234-806-398-6286">
                            {" "}
                            <span>+234-806-398-6286</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src="images/ils_03.svg" alt="" className="shapes illustration" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-11 m-auto">
              <div className="bottom-footer">
                <div className="row">
                  <div className="col-lg-4 order-lg-0 mb-15">
                    <ul className="d-flex justify-content-center justify-content-lg-start footer-nav style-none">
                      <li>
                        <a href="/contact">
                          <span>Contact Ugi</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 order-lg-2 mb-15">
                    <ul className="d-flex justify-content-center justify-content-lg-end social-icon style-none">
                      <li>
                        <a
                          href="https://www.facebook.com/docaustyne"
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/docaustyne"
                          target="_blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/docaustyne"
                          target="_blank"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/in/docaustyne/"
                          target="_blank"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.github.com/docaustyne"
                          target="_blank"
                        >
                          <i className="fab fa-github"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 order-lg-1 mb-15">
                    <p className="copyright text-center">
                      Copyright @2022 UAU.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default footer;
