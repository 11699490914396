import React from "react";

function timeline() {
  return (
    <div>
      <div className="section section-md py-5">
        <div className="container">
          <div className="row">
            <div className="title-style-one text-center">
              <h2 className="title">Timeline</h2>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-10 mx-auto">
              <div className="timeline timeline-one">
                <div className="timeline-item">
                  <span className="icon icon-info icon-lg">
                    {/* <div class="icon d-flex align-items-end"> */}

                    <img src="images/icon/icon_55.svg" alt="" />
                    {/* </div> */}
                  </span>
                  <h5 style={{ fontFamily: "gorditamedium" }} className="my-3">
                    Middlesex University
                  </h5>
                  <p>
                    Bachelor of Science Information Technology & Business
                    information Systems
                  </p>
                  <p>2013-01</p>
                </div>

                <div className="timeline-item">
                  <span className="icon icon-secondary">
                    <img src="images/icon/icon_55.svg" alt="" />
                  </span>
                  <h5 style={{ fontFamily: "gorditamedium" }} className="my-3">
                    National Institute of Information Technology
                  </h5>
                  <p>Higher National Diploma Software Engineering</p>
                  <p>2012-01</p>
                </div>

                <div className="timeline-item">
                  <span className="icon icon-danger">
                    <img src="images/icon/icon_58.svg" alt="" />
                  </span>
                  <h5 style={{ fontFamily: "gorditamedium" }} className="my-3">
                    YAYA COMPUTERS, CROSS RIVER STATE
                  </h5>
                  <p>Cafe/Systems Administrator</p>
                  <p>2008-08 - 2009-08</p>
                </div>
                <div className="timeline-item">
                  <span className="icon icon-secondary">
                    <img src="images/icon/icon_58.svg" alt="" />
                  </span>
                  <h5 style={{ fontFamily: "gorditamedium" }} className="my-3">
                    Software and Network Intern
                  </h5>
                  <p>Hot-Minet Services Ltd</p>
                  <p>2009-11 - 2012-12</p>
                </div>
                <div className="timeline-item">
                  <span className="icon icon-danger">
                    <img src="images/icon/icon_58.svg" alt="" />
                  </span>
                  <h5 style={{ fontFamily: "gorditamedium" }} className="my-3">
                    Chief Executive Officer
                  </h5>
                  <p>NUGI TECHNOLOGIES NIG LTD</p>
                  <p>2012-01 - Current</p>
                </div>
                <div className="timeline-item">
                  <span className="icon icon-secondary">
                    <img src="images/icon/icon_58.svg" alt="" />
                  </span>
                  <h5 style={{ fontFamily: "gorditamedium" }} className="my-3">
                    Chief Software Engineer
                  </h5>
                  <p>GTCO CALSCAN NIGERIA LIMITED</p>
                  <p>2014-02 - 2015-01</p>
                </div>
                <div className="timeline-item">
                  <span className="icon icon-danger">
                    <img src="images/icon/icon_58.svg" alt="" />
                  </span>
                  <h5 style={{ fontFamily: "gorditamedium" }} className="my-3">
                    Information Technology Manager
                  </h5>
                  <p>Faculty Of Science, University Of Calabar</p>
                  <p>2014-08 - 2015-08</p>
                </div>
                <div className="timeline-item">
                  <span className="icon icon-secondary">
                    <img src="images/icon/icon_59.svg" alt="" />
                  </span>
                  <h5 style={{ fontFamily: "gorditamedium" }} className="my-3">
                    Cyber Security and its Ten Domains
                  </h5>
                  <p>2015-11</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default timeline;
