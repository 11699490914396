import CareerTab from "../careerTab/careerTab";
import OthersTab from "../othersTab/othersTab";
import React from "react";

// console.log(imageUrl, "This is image URL");
const imageUrl = process.env.REACT_APP_IMAGE_URL;
const charityImages = [
  `${imageUrl}images/Charity/IMG-20210927-WA0005.jpg`,

  `${imageUrl}images/Charity/IMG-20210930-WA0010.jpg`,
  `${imageUrl}images/Charity/IMG-20211210-WA0059.jpg`,
  `${imageUrl}images/Charity/IMG-20211210-WA0060.jpg`,
  `${imageUrl}images/Charity/IMG-20210930-WA0015.jpg`,
  `${imageUrl}images/Charity/IMG-20211210-WA0013.jpg`,
];
const familyImages = [
  `${imageUrl}images/Family-Images/IMG-20211211-WA0026.jpg`,
  `${imageUrl}images/Family-Images/IMG-20201025-WA0008.jpg`,
  `${imageUrl}images/Family-Images/IMG-20201231-WA0014.jpg`,
];
function Gallery() {
  return (
    <div>
      <div className="case-study-seven pb-60">
        <h2 className="text-center font-recoleta fw-bold pt-120 pb-80">
          Gallery
        </h2>
        <div className="container">
          <ul className="style-none text-center isotop-menu-wrapper control-nav-two pb-90 lg-pb-60">
            <li
              className="is-checked"
              style={{ fontFamily: "gorditaregular" }}
              data-filter="*"
            >
              All
            </li>
            <li style={{ fontFamily: "gorditaregular" }} data-filter=".dev">
              Family
            </li>
            <li style={{ fontFamily: "gorditaregular" }} data-filter=".plugin">
              Charity
            </li>
            <li style={{ fontFamily: "gorditaregular" }} data-filter=".custom">
              Career
            </li>
            <li style={{ fontFamily: "gorditaregular" }} data-filter=".others">
              Others
            </li>
          </ul>
        </div>
        <div className="box-layout">
          <div id="isotop-gallery-wrapper">
            <div className="grid-sizer"></div>
            <CareerTab />
            <OthersTab />
            <div className="isotop-item plugin">
              <div className="case-block-six">
                <div className="img-box">
                  <img
                    src={`${imageUrl}images/Charity/IMG-20210927-WA0005.jpg`}
                    alt=""
                  />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/Charity/IMG-20210927-WA0005.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="isotop-item dev">
              <div className="case-block-six">
                <div className="img-box">
                  <img src={`${imageUrl}images/boss-family-01.jpg`} alt="" />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/boss-family-01.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
                <div className="img-box">
                  <img src={`${imageUrl}images/boss-family-01.jpg`} alt="" />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/boss-family-01.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="isotop-item plugin">
              <div className="case-block-six">
                <div className="img-box">
                  <img
                    src={`${imageUrl}images/Charity/IMG-20210930-WA0010.jpg`}
                    alt=""
                  />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/Charity/IMG-20210930-WA0010.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="case-block-six">
                <div className="img-box">
                  <img
                    src={`${imageUrl}images/Charity/IMG-20211210-WA0059.jpg`}
                    alt=""
                  />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/Charity/IMG-20211210-WA0059.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="case-block-six">
                <div className="img-box">
                  <img
                    src={`${imageUrl}images/Charity/IMG-20211210-WA0060.jpg`}
                    alt=""
                  />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/Charity/IMG-20211210-WA0060.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="isotop-item dev">
              <div className="case-block-six">
                <div className="img-box">
                  <img src={`${imageUrl}images/boss-family-02.jpg`} alt="" />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/boss-family-02.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
                <div className="img-box">
                  <img src={`${imageUrl}images/boss-family-02.jpg`} alt="" />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/boss-family-02.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="isotop-item dev">
              <div className="case-block-six">
                <div className="img-box">
                  <img src={`${imageUrl}images/boss-charity-03.jpg`} alt="" />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/boss-charity-03.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
                <div className="img-box">
                  <img src={`${imageUrl}images/boss-charity-03.jpg`} alt="" />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/boss-charity-03.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="isotop-item dev">
              <div className="case-block-six">
                <div className="img-box">
                  <img src={`${imageUrl}images/boss-charity-02.jpg`} alt="" />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/boss-charity-02.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
                <div className="img-box">
                  <img src={`${imageUrl}images/boss-charity-02.jpg`} alt="" />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/boss-charity-02.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="isotop-item dev ">
              <div className="case-block-six">
                <div className="img-box">
                  <img
                    src={`${imageUrl}images/Family-Images/IMG-20201231-WA0014.jpg`}
                    alt=""
                  />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/Family-Images/IMG-20201231-WA0014.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="case-block-six">
                <div className="img-box">
                  <img
                    src={`${imageUrl}images/Family-Images/IMG-20211211-WA0030.jpg`}
                    alt=""
                  />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/Family-Images/IMG-20211211-WA0030.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="case-block-six">
                <div className="img-box">
                  <img
                    src={`${imageUrl}images/Family-Images/IMG-20201231-WA0018.jpg`}
                    alt=""
                  />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/Family-Images/IMG-20201231-WA0018.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="isotop-item plugin">
              <div className="case-block-six">
                <div className="img-box">
                  <img
                    src={`${imageUrl}images/Charity/IMG-20210930-WA0015.jpg`}
                    alt=""
                  />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/Charity/IMG-20210930-WA0015.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="case-block-six">
                <div className="img-box">
                  <img
                    src={`${imageUrl}images/Charity/IMG-20211210-WA0020.jpg`}
                    alt=""
                  />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/Charity/IMG-20211210-WA0020.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="case-block-six">
                <div className="img-box">
                  <img
                    src={`${imageUrl}images/Charity/IMG-20211210-WA0047.jpg`}
                    alt=""
                  />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/Charity/IMG-20211210-WA0047.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="isotop-item plugin ">
              <div className="case-block-six">
                <div className="img-box">
                  <img
                    src={`${imageUrl}images/Charity/IMG-20211210-WA0013.jpg`}
                    alt=""
                  />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/Charity/IMG-20211210-WA0013.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="case-block-six">
                <div className="img-box">
                  <img
                    src={`${imageUrl}images/Charity/IMG-20211210-WA0049.jpg`}
                    alt=""
                  />
                </div>
                <div className="hover-valina">
                  <div>
                    <a
                      className="fancybox position-relative zoom"
                      data-fancybox=""
                      href={`${imageUrl}images/Charity/IMG-20211210-WA0049.jpg`}
                      tabindex="0"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
