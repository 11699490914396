import React from "react";

function othersTab() {
  return (
    <div>
      <div className="isotop-item others">
        <div className="case-block-six">
          <div className="img-box">
            <img src={`${process.env.REACT_APP_IMAGE_URL}images/Others/IMG-20201024-WA0003.jpg`} alt="" />
          </div>
          <div className="hover-valina">
            <div>
              <a
                className="fancybox position-relative zoom"
                data-fancybox=""
                href={`${process.env.REACT_APP_IMAGE_URL}images/Others/IMG-20201024-WA0003.jpg`}
                tabindex="0"
              >
                <i className="bi bi-arrows-angle-expand"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* 2 */}
      <div className="isotop-item others">
        <div className="case-block-six">
          <div className="img-box">
            <img src={`${process.env.REACT_APP_IMAGE_URL}images/Others/IMG-20201025-WA0001.jpg`} alt="" />
          </div>
          <div className="hover-valina">
            <div>
              <a
                className="fancybox position-relative zoom"
                data-fancybox=""
                href={`${process.env.REACT_APP_IMAGE_URL}images/Others/IMG-20201025-WA0001.jpg`}
                tabindex="0"
              >
                <i className="bi bi-arrows-angle-expand"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* 3 */}
      <div className="isotop-item others">
        <div className="case-block-six">
          <div className="img-box">
            <img src={`${process.env.REACT_APP_IMAGE_URL}images/Others/IMG-20201025-WA0010.jpg`} alt="" />
          </div>
          <div className="hover-valina">
            <div>
              <a
                className="fancybox position-relative zoom"
                data-fancybox=""
                href={`${process.env.REACT_APP_IMAGE_URL}images/Others/IMG-20201025-WA0010.jpg`}
                tabindex="0"
              >
                <i className="bi bi-arrows-angle-expand"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* 4 */}
      <div className="isotop-item others">
        <div className="case-block-six">
          <div className="img-box">
            <img src={`${process.env.REACT_APP_IMAGE_URL}images/Others/IMG-20210802-WA0003.jpg`} alt="" />
          </div>
          <div className="hover-valina">
            <div>
              <a
                className="fancybox position-relative zoom"
                data-fancybox=""
                href={`${process.env.REACT_APP_IMAGE_URL}images/Others/IMG-20210802-WA0003.jpg`}
                tabindex="0"
              >
                <i className="bi bi-arrows-angle-expand"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* 5 */}
      <div className="isotop-item others">
        <div className="case-block-six">
          <div className="img-box">
            <img src={`${process.env.REACT_APP_IMAGE_URL}images/Others/IMG-20210802-WA0004.jpg`} alt="" />
          </div>
          <div className="hover-valina">
            <div>
              <a
                className="fancybox position-relative zoom"
                data-fancybox=""
                href={`${process.env.REACT_APP_IMAGE_URL}images/Others/IMG-20210802-WA0004.jpg`}
                tabindex="0"
              >
                <i className="bi bi-arrows-angle-expand"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* 6 */}
      <div className="isotop-item others">
        <div className="case-block-six">
          <div className="img-box">
            <img src={`${process.env.REACT_APP_IMAGE_URL}images/Others/20211007_070559.jpg `} alt="" />
          </div>
          <div className="hover-valina">
            <div>
              <a
                className="fancybox position-relative zoom"
                data-fancybox=""
              href={`${process.env.REACT_APP_IMAGE_URL}images/Others/20211007_070559.jpg"
`}             tabindex="0"
              >
                <i className="bi bi-arrows-angle-expand"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default othersTab;
