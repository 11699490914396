// import logo from "./logo.svg";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import "./App.css";
import Hompage from "./components/homepage/hompage";
import Contact from "./contact/contact";
import Career from "./components/career/career";
import Loader from "./components/loader";
import About from "./components/about/about";
import AOS from "aos";
import "aos/dist/aos.css";
import Insights from "./components/insights/insights";

function App() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@docaustyne/"
    )
      .then((resp) => resp.json())
      .then((data) => {
        setBlogs(data.items);
      });
  }, []);
  // eslint-disable-next-line no-lone-blocks

  //   const newBlog = blogs.map((blog, index) => {
  //     if (index < 2) {
  //         return blog
  //     }
  //   })
  // console.log(blogs, "you don work?");

  AOS.init();
  return (
    <div>
      <BrowserRouter>
        <Loader />
        <Routes>
          <Route path="/" element={<Hompage data={blogs} />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/insights" element={<Insights data={blogs} />} />
        </Routes>
      </BrowserRouter>
      {/* <Hompage /> */}
    </div>
  );
}

export default App;
