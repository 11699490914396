import React from "react";

function workHistory() {
  return (
    <div>
      <div
        className="title-style-two text-center"
        style={{ fontFamily: "gorditaregular", marginTop: "5rem" }}
        id="work"
      >
        <h3 className="title">
          Work <span className="history">History</span>
        </h3>
        {/* <small><code>Mouse Hover on "Icon Menu"</code></small> */}
      </div>
      <div className="faq-section-one mt-120 mb-150 lg-mt-100 lg-mb-120">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-lg-4 faq-sidebar">
              <div className="faq-nav-one">
                <div id="faq-list-nav" className="list-group">
                  <a
                    style={{ fontFamily: "gorditaregular" }}
                    className="list-group-item list-group-item-action"
                    href="#list-item-1"
                  >
                    Nugi Group
                  </a>
                  <a
                    style={{ fontFamily: "gorditaregular" }}
                    className="list-group-item list-group-item-action"
                    href="#list-item-2"
                  >
                    Nugi Technologies
                  </a>
                  <a
                    style={{ fontFamily: "gorditaregular" }}
                    className="list-group-item list-group-item-action"
                    href="#list-item-3"
                  >
                    Tailorgang
                  </a>
                  <a
                    style={{ fontFamily: "gorditaregular" }}
                    className="list-group-item list-group-item-action"
                    href="#list-item-4"
                  >
                    CloudSkul-Edustack
                  </a>
                  <a
                    style={{ fontFamily: "gorditaregular" }}
                    className="list-group-item list-group-item-action"
                    href="#list-item-5"
                  >
                    GTCO CALSCAN
                  </a>
                  <a
                    style={{ fontFamily: "gorditaregular" }}
                    className="list-group-item list-group-item-action"
                    href="#list-item-6"
                  >
                    Hot Minet Services
                  </a>
                  <a
                    style={{ fontFamily: "gorditaregular" }}
                    className="list-group-item list-group-item-action"
                    href="#list-item-7"
                  >
                    Yaya
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-7 col-lg-8 ms-auto faq-main-body">
              <div className="clearfix">
                <div
                  className="accordion accordion-style-two"
                  id="accordionTwo"
                >
                  <div className="accordion-tag md-pt-80" id="list-item-1">
                    Nugi Group
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        style={{ height: "12px" }}
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        President @ Nugi Group <br />
                      </button>
                      <p>January 2020 - Present</p>
                    </div>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      style={{ border: "none" }}
                      data-bs-parent="#accordionTwo"
                    >
                      <div className="accordion-body">
                        <p>
                          Impossible Made Inevitable From the very beginning of
                          Nugi, we have always been about overcoming our fears,
                          our walls and limitations. We have always believed
                          that we can build and contribute to society by doing
                          the very little things. Nugi Represents the present
                          and the future, identifying areas that needs help and
                          focusing our strength in building technology that can
                          help solve the many challenges of the African society.
                          .
                        </p>
                        <br />
                        <p>
                          We started out with Nugi Technologies, about finding
                          the path and creating foot prints that others could
                          follow. Today we are now a group, firm with those same
                          believes that we can always overcome any obstacles and
                          make an impact. Our progression into the future has
                          been slow, but our believe in the Nugi project has
                          been firm and unwavering.
                        </p>
                        <br />
                        <p>
                          Our mission in the Nugi group is to continue impacting
                          society using technology in new areas. In education,
                          Photography, fashion and subsequently agriculture. We
                          believe that what seems impossible can be made
                          inevitable and this is how we are wired up at Nugi.
                        </p>
                        <br />
                        <p>
                          Our company is running well and open, but we believe
                          we can run a better open structure and communicate
                          better with the public in the coming years. We also
                          believe we can be more accountable and as we become a
                          group of companies, we want to reflect openness in all
                          our dealings, integrity at the core of our operations
                          and the way we treat those who work for our collective
                          goals.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="accordion accordion-style-two"
                  id="accordionThree"
                >
                  <div
                    className="accordion-tag pt-90 lg-pt-80"
                    id="list-item-2"
                  >
                    Nugi Technologies
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOneA">
                      <button
                        className="accordion-button collapsed"
                        style={{ height: "12px" }}
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOneA"
                      >
                        CEO @ Nugi Technologies
                      </button>
                      <p>May 2019 - August 2022</p>
                    </div>
                    <div
                      id="collapseOneA"
                      className="accordion-collapse collapse"
                      style={{ border: "none" }}
                      data-bs-parent="#accordionThree"
                    >
                      <div className="accordion-body">
                        <p>
                          My idea behind Nugitech was very simple, ‘’for devs by
                          devs”. I set out to creating a place where young
                          people could build things they loved, where ideas
                          could become reality at a snap. I registered Nugitech
                          in January of 2016 backed up with over 8 years of
                          experience doing what I loved.
                        </p>
                        <br />
                        <p>
                          I have been able to take Nugitech from a very rocky
                          start in 2016 to an experienced organisation in 2020
                          with clients in Aviation, Education, and Financial
                          economy of Nigeria.
                        </p>
                        <br />
                        <p>
                          Our Vision in Nugi technologies has always been to
                          attract everyone who is willing and loves to work from
                          every culture, this is reflective in our workforce
                          over the years. Our Mission remains to empower society
                          building the best customer-centric software solutions
                          focused on creating economic impact directly of
                          indirectly; this has also been reflective in the kind
                          of products we build.{" "}
                        </p>
                        <br />
                        <p>
                          Over the years, we have built solutions like Cloudskul
                          (Edustack), Onemoni, Payone, Tailorgang, Ultime-Plus
                          Education solution for tertiary institutions, and many
                          other products for clients in Government and the
                          Private sectors.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="accordion accordion-style-two"
                  id="accordionFour"
                >
                  <div
                    className="accordion-tag pt-90 lg-pt-80"
                    id="list-item-3"
                  >
                    Tailorgang
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOneB">
                      <button
                        className="accordion-button collapsed"
                        style={{ height: "12px" }}
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOneB"
                      >
                        Co-Founder @ Tailorgang
                      </button>
                      <p>May 2018 - Present</p>
                    </div>
                    <div
                      id="collapseOneB"
                      className="accordion-collapse collapse"
                      style={{ border: "none" }}
                      data-bs-parent="#accordionFour"
                    >
                      <div className="accordion-body">
                        <p>
                          In 2017, I co-founded Tailorgang with my wife, seeking
                          to help thousands of small business within the fashion
                          business; I have learnt so much within that time. We
                          have failed a lot of times on this project but we
                          continue to test new things until we get it right.
                        </p>
                        <br />
                        <p>
                          Making a slam-dunk move in 2019, we moved to Lagos to
                          be closer to the fashion industry in Nigeria, a move
                          that made us open up to learning more about an
                          industry that can save the economy of Nigeria post
                          Covid-19.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="accordion accordion-style-two"
                  id="accordionFive"
                >
                  <div
                    className="accordion-tag pt-90 lg-pt-80"
                    id="list-item-4"
                  >
                    CloudSkul-Edustack
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOneC">
                      <button
                        className="accordion-button collapsed"
                        style={{ height: "12px" }}
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOneC"
                      >
                        Founder @ CloudSkul-Edustack
                      </button>
                      <p>November 2015 - Present</p>
                    </div>
                    <div
                      id="collapseOneC"
                      className="accordion-collapse collapse"
                      style={{ border: "none" }}
                      data-bs-parent="#accordionFive"
                    >
                      <div className="accordion-body">
                        <p>
                          I founded Cloudskul (now edustack) to help small
                          schools across the world access cheap technology. My
                          mission was to empower those schools who couldn’t
                          afford high subscriptions and who could not deploy
                          open source applications on either on-premise or cloud
                          hosted servers.
                        </p>
                        <br />
                        <p>
                          Without funding, we could not drive the project as
                          expected but so far; Cloudskul helped over 900 schools
                          globally between 2015 and now and kept them going with
                          an all time free access to the app. We are currently
                          reviving the project and planning re-launching as
                          Edustack.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="accordion accordion-style-two"
                  id="accordionSix"
                >
                  <div
                    className="accordion-tag pt-90 lg-pt-80"
                    id="list-item-5"
                  >
                    GTCO CALSCAN
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOneD">
                      <button
                        className="accordion-button collapsed"
                        style={{ height: "12px" }}
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOneD"
                      >
                        C.S.E / C.T.O @ GTCO CALSCAN
                      </button>
                      <p>January 2013 - April 2016</p>
                    </div>
                    <div
                      id="collapseOneD"
                      className="accordion-collapse collapse"
                      style={{ border: "none" }}
                      data-bs-parent="#accordionSix"
                    >
                      <div className="accordion-body">
                        <p>
                          Working @GTCO was a bit challenging, especially
                          pioneering new technologies at the time, worked to
                          oversee every product development from initiation to
                          gathering of requirements with clients, development
                          and deployment.
                        </p>
                        <br />
                        <p>
                          With my supervision and dedication, we built a good
                          model for every client of the company, establishing
                          good software products still in use to date.{" "}
                        </p>
                        <br />
                        <p>
                          Outside actively building and deploying most of the
                          technology as Chief software Engineer, I also
                          pioneered the companies business innovation and
                          directions, supervised staff and evaluate their
                          performances, I doubled up sometimes as the business
                          developer and represented the company in several
                          business presentations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="accordion accordion-style-two"
                  id="accordionSeven"
                >
                  <div
                    className="accordion-tag pt-90 lg-pt-80"
                    id="list-item-6"
                  >
                    Hot Minet Services
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOneE">
                      <button
                        className="accordion-button collapsed"
                        style={{ height: "12px" }}
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOneE"
                      >
                        Intern @ Hot Minet Services
                      </button>
                      <p>August 2008 - February 2010</p>
                    </div>
                    <div
                      id="collapseOneE"
                      className="accordion-collapse collapse"
                      style={{ border: "none" }}
                      data-bs-parent="#accordionSeven"
                    >
                      <div className="accordion-body">
                        <p>
                          I started an unpaid internship with Hot-Minet services
                          in 2010 and helped the company to build entry level
                          customer relationship applications, wifi login pages
                          and payment systems. This is where I also got to learn
                          about Networking systems for city wide wifi, this was
                          an added advantage in providing requisite skills set
                          that I still use across projects today.
                        </p>
                      </div>
                      With my guidance and supervision, the Cafe opened up a
                      school to teach people basic computer knowledge where I
                      taught Microsoft office suit, Surfing the internet and
                      other things students needed to know at that time. With my
                      guidance and supervision, the Cafe opened up a school to
                      teach people basic computer knowledge where I taught
                      Microsoft office suit, Surfing the internet and other
                      things students needed to know at that time.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion accordion-style-two"
                  id="accordionEight"
                >
                  <div
                    className="accordion-tag pt-90 lg-pt-80"
                    id="list-item-7"
                  >
                    Yaya
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOneF">
                      <button
                        className="accordion-button collapsed"
                        style={{ height: "12px" }}
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOneF"
                      >
                        Network Administrator @ Yaya
                      </button>
                      <p>Januaruy 2007 - August 2008</p>
                    </div>
                    <div
                      id="collapseOneF"
                      className="accordion-collapse collapse"
                      style={{ border: "none" }}
                      data-bs-parent="#accordionEight"
                    >
                      <div className="accordion-body">
                        <p>
                          My first IT job was at Yaya Cafe, one of the earliest
                          cafe’s in Calabar in 2006. I worked as a cafe admin
                          and saw to the daily operations of the cafe. My job
                          here was easy, outside maintaining the systems to
                          ensure they were in good shape, it was my primary duty
                          to support customers and ensure they had a good time
                          surfing the internet.{" "}
                        </p>
                        <br />
                        <p>
                          With my guidance and supervision, the Cafe opened up a
                          school to teach people basic computer knowledge where
                          I taught Microsoft office suit, Surfing the internet
                          and other things students needed to know at that time.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <img src="images/shape/shape_36.svg" alt="" className="shapes shape-one"/>
				<img src="images/shape/shape_37.svg" alt="" className="shapes shape-two"/> */}
      </div>
    </div>
  );
}

export default workHistory;
