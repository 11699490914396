import React from "react";
import ReactTooltip from "react-tooltip";

const imageUrl = process.env.REACT_APP_IMAGE_URL;
const brandImages = [
  {
    name: "Nugi Technologies",
    image: `${imageUrl}images/brands/logo-blue.png`,
  },
  {
    name: "Syncventory",
    image: `${imageUrl}images/brands/Syncventory+logo+2+3.svg`,
  },
  {
    name: "Bosscab",
    image: `${imageUrl}images/bosscab.png`,
  },
  {
    name: "Nugi Innovations",
    image: `${imageUrl}images/brands/02.png`,
  },
  {
    name: "Cross River State",
    image: `${imageUrl}images/crslogo.svg`,
  },
  {
    name: "Edustack",
    image: `${imageUrl}images/brands/edustack.png`,
  },

  {
    name: "Clothier360",
    image: `${imageUrl}images/brands/logo.png`,
  },
  {
    name: "Nugi Frames",
    image: `${imageUrl}images/brands/nugiframes.png`,
  },
  {
    name: "Tailorgang",
    image: `${imageUrl}images/brands/tailorgang.png`,
  },
  {
    name: "Nugi Groups",
    image: `${imageUrl}images/brands/nugigroup.png`,
  },
  {
    name: "Etranzact",
    image: `${imageUrl}images/brands/etransact.png`,
  },
  {
    name: "Credo",
    image: `${imageUrl}images/brands/credo.png`,
  },
  {
    name: "GTCO CALSCAN",
    image: `${imageUrl}images/brands/gto.png`,
  },
  {
    name: "Delta Tech Africa",
    image: `${imageUrl}images/brands/dta.png`,
  },
  {
    name: "Aajimatics",
    image: `${imageUrl}images/brands/aajibg.png`,
  },
];
function patners() {
  return (
    <div>
      <div className="partner-section-two pt-180 mb-200 lg-pt-50 lg-mb-100">
        <img
          src="images/shape/shape_13.svg"
          alt=""
          className="shapes shape-one"
        />
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-8 col-lg-6 col-md-8 col-sm-10 m-auto">
              <div className="title-style-one text-center">
                {/* <div className="upper-title">Our partners</div> */}
                <h2 className="title text-center">
                  Businesses I have created, Impacted or worked with
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-11 m-auto">
              <div className="partent-logos">
                <div className="row g-0">
                  {brandImages.map((brand, id) => (
                    <div className="col-md-3 col-6" key={id}>
                      <div
                        data-tip={brand.name}
                        data-place="bottom"
                        className="logo d-flex align-items-center justify-content-center"
                      >
                        <a href="#">
                          <img
                            style={{ height: "50px" }}
                            src={brand.image}
                            alt=""
                            className="tran3s"
                          />
                        </a>
                      </div>
                      <ReactTooltip globalEventOff="click" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default patners;
