import React from "react";


function company() {
  return (
    <div>
      <div className="vcamp-feature-section-three border-bottom border-top  pt-35 pb-60 md-pb-40 md-pt-10">
        <div className="vcamp-text-block-eleven mt-140 lg-mt-120 mb-3">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="title-style-one">
                  {/* <div className="upper-title">Why choose us</div> */}
                  <h6 className="title">
                    Optimism laced with realism is the only way to face our
                    uncertain future.
                  </h6>
                </div>
              </div>
              <div className="col-lg-6 ms-auto">
                <p className="meta-info-text md-pt-30" style={{color:"rgba(0,0,0,.4)"}}>
                  Through out my career in technology, I have worked hard to
                  create opportunities and build solutions that are community
                  focused, The future of our continent won't just happen, we
                  have to build it together as community, driving changes
                  through technology.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <miniAbout /> */}
        <div className="container">
          <div className="row gx-xl-5 justify-content-between">
            <div className="col-md-4 col-sm-6" data-aos="fade-up">
              <div className="card-style-three mt-50">
                <div className="icon d-flex align-items-center justify-content-center">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}images/community.png`}
                    alt=""
                    style={{height: "40px"}}
                    className="tran3s"
                  />
                </div>
                <h4 className="title">Community Focused Solutions</h4>
                <p>Driving changes in rural communities through new models..</p>
              </div>
            </div>
            <div
              className="col-md-4 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card-style-three mt-50">
                <div className="icon d-flex align-items-center justify-content-center">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}images/education.png`}
                    alt=""
                    style={{height: "40px"}}
                    className="tran3s"
                  />
                </div>
                <h4 className="title">Education Focused.</h4>
                <p>
                Innovating New technology to address education outcomes across Africa.
                </p>
              </div>
            </div>
            <div
              className="col-md-4 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="card-style-three mt-50">
                <div className="icon d-flex align-items-center justify-content-center">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}images/young.png`}
                    alt=""
                    style={{height: "40px"}}
                    className="tran3s"
                  />
                </div>
                <h4 className="title">Opportunities for young people.</h4>
                <p>
                Providing opportunities for young people to build up their career in Technology.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default company;
