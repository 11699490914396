import React from 'react'

function loader() {
    return (
        <div>
             <section>
            <div id="preloader">
              <div id="ctn-preloader" className="ctn-preloader">
                <div className="animation-preloader">
                  <div className="icon">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_URL}images/newfav.png`}
                      alt=""
                      className="m-auto d-block"
                      style={{ width: "5%" }}
                    />{" "}
                    <span></span>
                  </div>
                  <div className="txt-loading mt-4 ">
                    <span
                      data-text-preloader="U"
                      className="letters-loading"
                      style={{ fontFamily: "Recoleta !important" }}
                    >
                      U
                    </span>
                    <span
                      data-text-preloader="A"
                      className="letters-loading"
                      style={{ fontFamily: "Recoleta !important" }}
                    >
                      A
                    </span>
                    <span
                      data-text-preloader="U"
                      className="letters-loading"
                      style={{ fontFamily: "Recoleta !important" }}
                    >
                      U
                    </span>
                    {/* <span data-text-preloader="P" className="letters-loading">
                      P
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    )
}

export default loader
