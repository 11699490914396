import Footer from "../footer/footer";
import Gallery from "../gallery/gallery";
import Header from "../header/header";
import React from "react";
function about() {
  // console.log(process.env.REACT_APP_IMAGE_URL, 'img for boss')
  return (
    <div>
      <Header />
      <div className="inside-hero-one hero-spacing">
        <div className="container">
          <h2 className="page-title font-recoleta">About Ugi</h2>
          {/* <p>We’re empowering WordPress to do great things together.</p> */}
        </div>
      </div>
      <div className="vcamp-text-block-one mt-200 lg-mt-120">
        <div className="container">
          <div className="row">
            <div
              className="col-xxl-5 col-lg-6 ms-auto order-lg-last"
              data-aos="fade-left"
            >
              <div className="text-wrapper pt-0">
                <div className="title-style-two">
                  <p className="meta-info-text-two">
                    I'm an entrepreneur skilled at strategic planning and
                    leadership. I love to give back to society the solutions
                    that reduce day-to-day problems and complex organisational
                    challenges, I build exceptional software solutions that help
                    scale organisations across different sectors of the economy.
                    Shortly after graduating from Middlesex University London
                    and working with several technology companies, I started my
                    company Nugi Technologies with an engineering team of 5 in
                    2016, where I was able to create and innovate solutions that
                    have scaled into standalone businesses. <br /> Ugi is
                    happily married to Nneotta Sally Ugi and a proud father of
                    Adrian and Augustine
                  </p>
                </div>
                {/* <h6>Who we are?</h6> */}
              </div>
            </div>
            <div className="col-xxl-6 col-lg-6" data-aos="fade-right">
              <div className="img-container position-relative">
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}images/boss-career-08.jpg`}
                  alt=""
                  className="ceo-avatar"
                  style={{ borderRadius: "12px" }}
                />
                <div className="quote-wrapper">
                  <img src="images/icon/icon_10.svg" alt="" className="icon" />
                  <blockquote>
                    Big Dreams? Just Create a Path, Create a Niche and find the
                    right Energy!
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="images/shape/shape_05.svg"
          alt=""
          className="shapes shape-two"
        />
      </div>
      <Gallery />
      <Footer />
    </div>
  );
}

export default about;
