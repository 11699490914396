import "./homepage.css";

import About from "../miniAbout/miniAbout";
import Business from "../patners/patners";
import Company from "../company/company";
import Footer from "../footer/footer";
// import Loader from "../loader";
import Header from "../header/header";
import { Link } from "react-router-dom";
import React from "react";

function hompage({ data }) {
  return (
    <div>
      <div className="main-page-wrapper">
        <div
          className="bigbg main-page-wrapper"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}images/boss-career-03.jpg)`,
          }}
        >
          {/* <Loader /> */}

          <Header />

          <div className="hero-banner-one mt-225 md-mt-140">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-8">
                  <h1 className="hero-heading font-recoleta theme-mb-0 text-white">
                    A better future for Everyone in Africa.
                  </h1>
                  <p className="hero-sub-heading text-lg text-white">
                    My name is Ugi Augustine Ugi, I leverage technology and
                    innovation, community driven solutions, and collaborations
                    to build a more inclusive, accessible and healthy Africa.
                  </p>
                  {/* <button > */}
                  <a
                    className="theme-btn-one ripple-btn"
                    href="mailto:docaustyn@gmail.com"
                  >
                    Get in Touch
                  </a>

                  {/* </button> */}
                  <p className="term-text theme-mb-0"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <Company />
        </div>

        <About />
        <Business />
        <div className="blog-section-two mb-120">
          <div className="container">
            <h2 style={{ textAlign: "center", fontFamily: "Recoleta" }}>
              Follow My Story
            </h2>
            <div className="row">
              {data.slice(0, 3).map((item, index) => (
                //   <div className="col-xxl-11 m-auto">

                // <div className="row gx-xl-5">
                <div className="col-md-4" key={index}>
                  <article className="blog-meta-two mt-40">
                    <div className="img-meta div-img">
                      <div
                        className="blog-img"
                        style={{
                          backgroundImage: `url(${item.thumbnail})`,
                          height: "300px",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "12px",
                          backgroundPosition: "center",
                        }}
                      ></div>
                    </div>
                    <div className="post-meta">
                      <div
                        className="date"
                        style={{ fontFamily: "gorditamedium" }}
                      >
                        {item.pubDate}
                      </div>
                      <a
                        href={item.link}
                        className="title"
                        style={{ textDecoration: "none" }}
                      >
                        {item.title}
                      </a>
                      {/* <a href="blog-detailsV3.html" className="theme-btn-three">
                      Continue reading <i className="fas fa-angle-right"></i>
                    </a> */}
                    </div>
                  </article>
                </div>

                // </div>
                //   </div>
              ))}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
              <Link to="/insights" className="theme-btn-one w-100 mt-30 mb-70">
                Read More
              </Link>
            </div>
            <div className="col-4"></div>
          </div>
        </div>

        <Footer />

        <button
          onClick={() => {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          }}
          className="scroll-top"
        >
          <i className="bi bi-arrow-up-short"></i>
        </button>
      </div>
    </div>
  );
}

export default hompage;
