import { Link } from "react-router-dom";
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router-dom";

function header() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();

  const links = ["/about", "/career", "/insights", "/contact"];

  function changeNavColor(link) {
    return links.includes(link)
      ? "nav-link dropdown-toggle"
      : " nav-link dropdown-toggle";
  }

  return (
    <div>
      <div>
        <header className="theme-main-menu sticky-menu theme-menu-one">
          <div className="inner-content bg-white pl-4 pt-2 rounded-xl ">
            <div className="d-flex align-items-center justify-content-between">
              <div className="logo">
                <Link to="/">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}images/UAU-blue.png`}
                    alt=""
                    width="127"
                  />
                </Link>
              </div>
              <nav className="navbar navbar-expand-lg">
                <button
                  className="navbar-toggler d-block d-lg-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav">
                    <li className="d-block d-lg-none">
                      <div className="logo">
                        <a href="index.html">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}images/UAU-blue.png`}
                            alt=""
                            width="127"
                          />
                        </a>
                      </div>
                    </li>
                    <li className="nav-item dropdown mega-dropdown-md">
                      <a
                        // className='nav-link text-black dropdown-toggle'
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        // className={
                        //   location.pathname === '/'
                        //     ? 'text-white nav-link dropdown-toggle'
                        //     : 'nav-link  dropdown-toggle'
                        // }
                        // href="/index.html"
                        className={changeNavColor(location.pathname)}
                        role="button"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                      >
                        Companies
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <div className="row">
                            <div className="col-lg-4 p-4">
                              {/* <div className="menu-column"> */}
                              {/* <h6 className="mega-menu-title">
                                      Essential
                                    </h6> */}
                              <img
                                src={`${process.env.REACT_APP_IMAGE_URL}images/compaies.jpg`}
                                alt=""
                                style={{ borderRadius: "10px" }}
                              />
                              {/* </div> */}
                            </div>
                            <div className="col-lg-4">
                              <div className="menu-column">
                                <h6 className="mega-menu-title">Companies</h6>
                                <ul className="style-none mega-dropdown-list">
                                  <li>
                                    <a
                                      href="https://nugitech.com/"
                                      className="dropdown-item"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span>Nugi Technologies</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://nugiframes.com/"
                                      className="dropdown-item"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span>Frames Studio</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://nugiinnovations.com/"
                                      className="dropdown-item"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span>Nugi Innovatins</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://tailorgang.io/"
                                      className="dropdown-item"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span>Tailorgang</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="menu-column">
                                {/* <h6 className="mega-menu-title">Others</h6> */}
                                <ul className="style-none mega-dropdown-list">
                                  <li>
                                    <a
                                      href="https://nugi.group/"
                                      className="dropdown-item"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span>Nugi Group</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://edustack.io/"
                                      className="dropdown-item"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span>Edu Stack</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://dtafrica.com/"
                                      className="dropdown-item"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span>Delta Tech Africa</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://clothier360.com/"
                                      className="dropdown-item"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span>Clothier360</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown mega-dropdown-md">
                      <a
                        // className={
                        //   location.pathname !== '/about' ||
                        //   '/career' ||
                        //   '/insights'
                        //     ? 'text-white nav-link dropdown-toggle'
                        //     : ' nav-link dropdown-toggle'
                        // }
                        className={changeNavColor(location.pathname)}
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                      >
                        Charity
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <div className="row">
                            <div className="col-lg-4 p-4">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_URL}images/career.jpg`}
                                alt=""
                                style={{ borderRadius: "10px" }}
                              />
                            </div>
                            <div className="col-lg-8">
                              <div className="menu-column">
                                {/* <h6 className="mega-menu-title">Companies</h6> */}
                                <ul className="style-none mega-dropdown-list">
                                  <li>
                                    <a
                                      href="#"
                                      className="dropdown-item"
                                      target="_blank"
                                    >
                                      <span>Artty Care Foundation</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="dropdown-item"
                                      target="_blank"
                                    >
                                      <span>
                                        Happy feet for kidz Foundation
                                      </span>
                                    </a>
                                  </li>
                                  {/* */}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <a
                      // className={
                      //   location.pathname !== '/about' ||
                      //   '/career' ||
                      //   'insights'
                      //     ? ' text-white nav-link'
                      //     : 'text-black nav-link '
                      // }
                      className={changeNavColor(location.pathname)}
                      href="/about"
                    >
                      About
                    </a>
                    <Link
                      // className={
                      //   location.pathname !== '/about' ||
                      //   '/career' ||
                      //   'insights'
                      //     ? 'text-white nav-link'
                      //     : 'text-black nav-link '
                      // }
                      className={changeNavColor(location.pathname)}
                      to="/career"
                    >
                      Career
                    </Link>

                    <Link
                      // className={
                      //   location.pathname !== '/about' ||
                      //   '/career' ||
                      //   'insights'
                      //     ? 'text-white nav-link'
                      //     : 'text-black nav-link '
                      // }

                      className={changeNavColor(location.pathname)}
                      to="/insights"
                    >
                      Insight
                    </Link>
                    {/* <li class="nav-link"><a href="/contact">Contact</a></li> */}
                    <Link
                      // className={
                      //   location.pathname !== '/about' ||
                      //   '/career' ||
                      //   'insights'
                      //     ? 'text-white nav-link'
                      //     : 'text-black nav-link '
                      // }
                      className={changeNavColor(location.pathname)}
                      to="/contact"
                    >
                      Contact
                    </Link>
                  </ul>
                </div>
              </nav>
              <div className="right-widget d-flex align-items-center">
                {/* <a
                      href="sign-in.html"
                      className="d-flex align-items-center login-btn"
                    >
                      <img src="images/icon/icon_01.svg" alt="" />
                      <span>login</span>
                    </a> */}
                {/* <button
                      className="sidebar-nav-button d-none d-lg-block"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                    >
                      <img src="images/icon/icon_02.svg" alt="" />
                    </button> */}
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
}

export default header;
