import "react-toastify/dist/ReactToastify.css";

import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { Link } from "react-router-dom";
import axios from "axios";

function Contact() {
  // const [inputs, setInputs] = useState({
  //   name: "",
  //   email: "",
  //   message:""
  // })
  const notify = () => toast("Thanks for contacting Ugi");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);

  const handleSubmit = () => {
    setLoading(true);
    setIsError(false);
    const data = {
      name: name,
      email: email,
      message: message,
    };
    axios
      .post("https://nws-applications.nugitech.com/application/apps/mail", data)
      .then((res) => {
        setData(res.data);
        setName("");
        setEmail("");
        setMessage("");
        setLoading(false);
        notify();
      })
      .catch((err) => {
        setLoading(false);
        setIsError(true);
      });
  };

  return (
    <div>
      <div className="user-data-page clearfix d-md-flex">
        <div
          className="illustration-wrapper d-none d-md-flex align-items-center justify-content-between flex-column"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}images/boss-career-11.jpg)`,
            backgroungRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* <div className="illustration-holder">
						<img src="images/bossaabout.jpg" alt="" className="illustration m-auto w-100"/>
					</div> */}
        </div>

        <div className="form-wrapper">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logo">
              <Link to="/">
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}images/UAU-blue.png`}
                  alt=""
                  width="127"
                />
              </Link>
            </div>
            <Link className="go-back-button" to="/">
              Go Home
            </Link>
          </div>

          <div
            className="form-style-one"
            style={{ padding: "0", marginRight: "0" }}
          >
            <form
              // onSubmit={submitHandle}
              action="#"
              id="C-form"
              className="user-data-form mt-60 lg-mt-50"
            >
              <h2 className="font-recoleta">Contact Ugi</h2>

              <div className="row">
                <ToastContainer />
                <div className="col-12">
                  <div className="input-group-meta mb-25">
                    <label>Name*</label>
                    <input
                      type="text"
                      placeholder="Athoinia Peter"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input-group-meta mb-25 has">
                    <label>Email*</label>
                    <input
                      type="email"
                      placeholder="myemail@gmail.com"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="input-group-meta form-group mb-35 has-error has-danger">
                    <textarea
                      id="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Your message*"
                      // name="message"
                      required="required"
                      data-error="Please,leave us a message."
                    ></textarea>
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-12">
                  <button
                    onClick={handleSubmit}
                    disabled={loading}
                    className="theme-btn-one w-100 mt-30 mb-70"
                  >
                    {" "}
                    {loading ? "Sending..." : "Submit"}
                  </button>
                  {/* {data && (
                    <div className="mt-3">
                      <strong className='history'>Sucess:</strong>
                      <br />
                      <pr>{JSON.stringify(data, null, 2)}</pr>
                    </div>
                  )} */}
                </div>
                <div className="col-12">
                  <p className="text-center font-rubik copyright-text">
                    Copyright @2022 UAU.
                  </p>
                  <div className="text-center font-rubik copyright-text">
                    <Link to="https://bold.pro/my/ugiaugustine-ugi">
                      Mr Ugi Augustine
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
